import React from "react";
import { Link, useLocation } from "react-router-dom";
import DoorIcon from "../../assets/navbar/Door.png";
import GlobeIcon from "../../assets/navbar/Globe.png";
import MusicIcon from "../../assets/navbar/Simple_Music.png";
import SecretIcon from "../../assets/navbar/keyhole-shape-in-a-black-circle.png";
import { IconsContainer, Icon } from "./Navbar.style";

const NavbarIcons = () => {
  const location = useLocation();

  const secret = localStorage.getItem("visitedSecretPage");
  return (
    <IconsContainer>
      <Link to="/">
        <Icon src={DoorIcon} selected={location.pathname === "/"} />
      </Link>
      <Link to="/stream">
        <Icon src={GlobeIcon} selected={location.pathname === "/stream"} />
      </Link>
      <Link to="/music">
        <Icon src={MusicIcon} selected={location.pathname === "/music"} />
      </Link>
      {secret !== null && (
        <Link to="/rabbit-hole">
          <Icon
            src={SecretIcon}
            selected={location.pathname === "/rabbit-hole"}
          />
        </Link>
      )}
    </IconsContainer>
  );
};

export default NavbarIcons;
