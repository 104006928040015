import React, { useEffect, useState } from "react";
import {
  StreamLink,
  MailContainer,
  MainContainer,
  SocialsContainer,
  StreamText,
} from "./Links.styles";
import MailModal from "./MailModal";

interface LinksProps {
  stopLoading: () => void;
}

const Links: React.FC<LinksProps> = ({ stopLoading }) => {
  const [mailModalVisible, setMailModalVisible] = useState<boolean>(false);
  const openMailModal = () => setMailModalVisible(true);
  const closeMailModal = () => setMailModalVisible(false);

  let mailModal;
  if (mailModalVisible) {
    mailModal = <MailModal closeModal={closeMailModal} />;
  }

  useEffect(() => {
    stopLoading();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContainer>
      {mailModal}
      <SocialsContainer>
        <StreamLink to="https://stream.kayduffbloom.com/" target="_blank">
          Stream
        </StreamLink>
        <StreamLink to="https://youtube.com/@kayduffbloom3497" target="_blank">
          Youtube
        </StreamLink>
        <StreamLink to="https://instagram.com/kayduffbloom" target="_blank">
          Instagram
        </StreamLink>
        <StreamLink
          to="https://www.tiktok.com/@kayduffbloom"
          target="_blank"
          style={{ marginBottom: 16 }}
        >
          Tiktok
        </StreamLink>

        <StreamLink
          to="https://newsletter.kayduffbloom.com/"
          target="_blank"
          style={{ marginBottom: 16 }}
        >
          Newsletter
        </StreamLink>

        <StreamLink to="https://soundcloud.com/kayduffbloom" target="_blank">
          Soundcloud
        </StreamLink>
        <StreamLink to="https://twitter.com/kayduffbloom" target="_blank">
          Twitter
        </StreamLink>
        <StreamLink to="https://www.tumblr.com/kayduffbloom" target="_blank">
          Blog
        </StreamLink>
      </SocialsContainer>
      <MailContainer>
        <StreamText onClick={openMailModal}>Mail</StreamText>
      </MailContainer>
    </MainContainer>
  );
};

export default Links;
